import { createDirectus, authentication, rest, readMe } from '@directus/sdk';
import router from '@/router';
import Cookies from 'js-cookie';

// Create a Directus client instance
const client = createDirectus(process.env.VUE_APP_API_ROOT).with(rest()).with(authentication('cookie', { credentials: 'include' }));

// Attempt to refresh the token
async function refreshToken() {
    try {
        const response = await client.refresh();
        if (response) {
            return true;
        }
        return false;
    } catch (error) {
        console.error("Failed to refresh token", error);
        return false;
    }
}

// Log in to Directus
async function logout() {
    // Log out of Directus
    try {
        await client.logout();
        Cookies.remove('directus_auth_token');
    } catch (error) {
        console.error("Failed to log out of Directus", error);
    }

    // Redirect to the login page
    router.push('/clients/login');
}

// Check if the user is logged in
async function check() {
    try {
        await client.request(
            readMe({
                fields: ['id']
            })
        );
        return true;
    } catch {
        // Attempt to refresh token if check fails and disable in development as it causes a loop
        if (process.env.NODE_ENV != 'development') {
            const refreshed = await refreshToken();
            if (refreshed) {
                return true;
            }
        }
        return false;
    }
}

// Handle Directus errors
function handleUnauthorised(error) {
    if (error.response.status === 401 || error.response.status === 403) {
        // Attempt to refresh the token
        refreshToken().then((refreshed) => {
            if (!refreshed) {
                // Remove the token from the cookie if unable to refresh
                Cookies.remove('directus_auth_token');
                
                // Redirect to the login page
                router.push('/clients/login');
            }
        });

        // Don't throw the error again
        return;
    }
    
    // Throw the error again so it can be caught by the caller
    throw error;
}

export { client, logout, check, handleUnauthorised };