<template>
    <!-- Router -->
    <router-view/>

    <!-- Footer -->
    <PageFooter/>
</template>

<script>
import PageFooter from '@/components/PageFooter.vue'

export default {
    components: {
        PageFooter
    },
    mounted() {
    },
    methods: {
    }
}
</script>

<style>
    @import '@/style.css';
</style>