<template>
    <div class="bg-slate-800">
        <div class="py-5 text-white">
            <div class="px-8 relative w-full max-w-6xl mx-auto">
                <p class="text">&copy; <span class="font-google-sans-regular font-thin">Alan Tiller 2024. All rights reserved.</span></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageFooter',
    components: {
    },
    methods: {
        
    }
}
</script>