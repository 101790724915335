import { createRouter, createWebHistory } from 'vue-router'
import { check } from '@/directus'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/blog',
        name: 'blog',
        component: () => import('../views/BlogView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/blog/:slug',
        name: 'blog-article',
        component: () => import('../views/BlogArticleView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/design-and-development',
        name: 'design-and-development',
        component: () => import('../views/DesignAndDevelopmentView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: () => import('../views/PortfolioView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/ContactView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/scout-badges/feedback',
        name: 'scout-badges-feedback',
        component: () => import('../views/ScoutBadgesFeedbackView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import('../views/clients/DashboardView.vue'),
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: '/clients/invoices',
        name: 'clients-invoices',
        component: () => import('../views/clients/InvoicesView.vue'),
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: '/clients/invoices/:id',
        name: 'clients-invoices-view',
        component: () => import('../views/clients/ViewInvoiceView.vue'),
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: '/clients/quotes',
        name: 'clients-quotes',
        component: () => import('../views/clients/QuotesView.vue'),
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: '/clients/quotes/:id',
        name: 'clients-quotes-view',
        component: () => import('../views/clients/ViewQuoteView.vue'),
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: '/clients/your-details',
        name: 'clients-your-details',
        component: () => import('../views/clients/YourDetailsView.vue'),
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: '/clients/login',
        name: 'clients-login',
        component: () => import('../views/clients/LoginView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/clients/forgot-password',
        name: 'clients-forgot-password',
        component: () => import('../views/clients/ForgotPasswordView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/clients/reset-password',
        name: 'clients-reset-password',
        component: () => import('../views/clients/ResetPasswordView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/new-look',
        name: 'new-look',
        component: () => import('../views/NewLookView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: () => import('../views/NotFoundView.vue'),
        meta: { 
            requiresAuth: false
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// Add a navigation guard to check for authentication
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const accessToken = await check();
        if (!accessToken) {
            next({
                path: '/clients/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router